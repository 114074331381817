.add-question-wrap {
  padding-right: 20px;
  .icon-remove {
    font-size: 0.9rem;
    color: var(--fonts-primary);
    stroke: var(--fonts-primary);
  }
  .quest-tag-blk {
    background: rgba(10, 117, 245, 0.01);
    border: 1px solid rgba(10, 117, 245, 0.15);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;
    margin-left: 24px;
    height: 100%;
    > h5 {
      font-weight: bold;
      font-size: 0.85rem;
      color: var(--fonts-primary);
    }
  }
  .question-slider-wrap {
    > p {
      font-weight: 800;
      color: var(--fonts-primary);
      font-size: 0.85rem;
    }
  }
  .question-tree {
    height: calc(100% - 168px);
    > ul {
      margin-top: 15px;
      > li {
        > .tree-item-content {
          border-bottom: 1px solid #dadee6;
          &.hover-active,
          &:hover {
            .icon-square-wrap {
              .icon-square {
                color: #0a75f5;
              }
            }
          }
          .icon-square {
            color: #0a75f5;
          }
          &.active-blk {
            border-color: transparent;
          }
        }
      }
      ul {
        li {
          .tree-item-content {
            &.hover-active,
            &:hover {
              .icon-square-wrap {
                .icon-square {
                  color: #f77320;
                }
              }
            }
            .icon-square {
              color: #f77320;
            }
          }
        }
        ul {
          li {
            .tree-item-content {
              &.hover-active,
              &:hover {
                .icon-square-wrap {
                  .icon-square {
                    color: #7146f7;
                  }
                }
              }
              .icon-square {
                color: #7146f7;
              }
            }
          }
          ul {
            li {
              .tree-item-content {
                &.disable-sqaures {
                  .icon-square-wrap {
                    .icon-square {
                      color: #d1d1d1;
                    }
                  }
                }
                &:hover,
                &.hover-active {
                  .icon-square-wrap {
                    .icon-square {
                      color: #f00af5;
                    }
                  }
                }

                .icon-square-wrap {
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background: #ffffff;
                    border-radius: 50%;
                  }
                  .icon-square {
                    color: #f00af5;
                  }
                }
              }
            }
          }
        }
      }
      li {
        position: relative;
        &:hover {
          &:before {
            opacity: 1;
          }
        }
        &:before {
          position: absolute;
          left: -5px;
          top: 4.5px;
          content: "";
          background: rgba(10, 117, 245, 0.1);
          height: 40px;
          width: calc(100% + 10px);
          border-radius: 4px;
          opacity: 0;
          transition: all ease 0.5s;
          z-index: -1;
        }
        &.child {
          position: relative;
          .tree-item-content.active-blk {
            .shape {
              position: absolute;
              content: "";
              left: 7px;
              height: calc(100% - 54px);
              width: 1px;
              background: #d2e5fb;
              top: 30px;
              z-index: 99;
            }
          }
        }
        ul {
          padding: 0 0 0 15px;
        }
        .tree-item-content {
          cursor: pointer;
          background: transparent;
          height: 50px;
          transition: all ease 0.4s;
          &.disable-sqaures {
            .icon-square-wrap {
              svg {
                color: #d1d1d1;
              }
            }
          }
          & + ul {
            transition: all ease 0.7s;
            opacity: 0;
            max-height: 0;
            li {
              position: relative;
              &:after {
                position: absolute;
                content: "";
                top: 25px;
                left: -7px;
                width: 9px;
                height: 1px;
                background: #d2e5fb;
              }
            }
          }
          &.active-blk {
            & + ul {
              opacity: 1;
              overflow: auto;
              max-height: 600px;
            }
            .right-blk {
              .tree-actions {
                .arrow {
                  transform: rotate(180deg);
                }
              }
            }
          }
          p.MuiTypography-root {
            color: var(--fonts-primary);
            font-size: 0.85rem;
          }
          .icon-square-wrap {
            margin-right: 10px;
            svg {
              transition: all ease 0.3s;
              width: 15px;
              height: 15px;
            }
          }
          .right-blk {
            display: flex;
            align-items: center;
            position: relative;
            transition: all ease 0.4s;
            padding-right: 0;
            .MuiFormControlLabel-root {
              margin: 0;
              .MuiCheckbox-root {
                padding: 0;
              }
            }
            .icon-rmv {
              color: var(--fonts-primary);
              font-size: 1.3rem;
            }
            .MuiTypography-root {
              font-size: 0.7rem;
            }
            .tree-actions {
              position: absolute;
              transform: translateY(10px);
              right: 0;
              display: flex;
              opacity: 0;
              transition: all ease 0.3s;
              svg {
                &:last-of-type {
                  margin-right: 0;
                }
              }
              &.lastChildActions {
                svg {
                  font-size: 1.2rem;
                }
              }
              .arrow {
                transition: all ease 0.3s;
              }
            }
          }
        }
      }
    }
  }
  .selected-tags {
    padding: 10px 18px;
    background: rgba(10, 117, 245, 0.03);
    border: 1px solid rgba(10, 117, 245, 0.15);
    border-radius: 4px;
    overflow: auto;
    display: flex;
    // &::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   border-radius: 10px;
    //   background-color: #f5f5f5;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border-radius: 10px;
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //   background-color: #d62929;
    // }
    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
      display: none;
    }
    .tagChip {
      font-size: 0.85rem;
      line-height: 32px;
      margin-right: 10px;
      .MuiChip-deleteIcon {
        margin: 0 0 0 5px;
        color: rgba(35, 65, 122, 0.34);
      }
    }
  }
  .occurrences-tag {
    h2 {
      font-size: 1rem;
      font-weight: 500;
      color: var(--fonts-primary);
      margin-bottom: 8px;
    }
    p {
      font-size: .75rem;
      line-height: 22px;
      color: var(--fonts-gray);
    }
  }
}
