.login-wrap {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    > div {
        flex:1;
    }
    .bg-wrap {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #E5E5E5;
        .logo-wrap {
            width: 30%;
            position: absolute;
            left: 65px;
            top: 65px;
        }
    }
    .login-blk {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .login-content {
            form {
                width: 375px;
            }
            h4 {
                color: #696969;
                font-size: 1.2rem;
                font-weight: 600;
                margin-bottom: 10px;
            }
            .link-forget {
                color: var(--btn-primary-bg);
                font-weight: 800;
                font-size: .75rem;
                margin-top: 10px;
                text-decoration: none;
                transition: all ease .3s;
                display: block;
                text-align: right;
                &:hover {
                    color: var(--color-primary);
                }
            }
            .btn-submit {
                background: var(--btn-primary-bg);
                border-radius: 4px;
                text-align: center;
                width: 100%;
                font-size: 1rem;
                color: var(--white);
                font-weight: bold;
                border: 0;
                box-shadow: none;
                height: 68px;
                margin-top: 36px;
                text-transform: capitalize;
                transition: all ease .3s;
                &:hover {
                    background: var(--btn-primary-bg-hover);
                }
                &:disabled {
                    background: var(--gray-secondary);
                }
            }
            .password-wrap {
                position: relative;
                .icon-box {
                    opacity: 0;
                    transition: all ease .3s;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    &.active {
                        opacity: 1;
                    }
                    span {
                        cursor: pointer;
                    }
                    .icon-password-hide {
                        font-size: .7rem;
                    }
                }
            }
        }
        h2 {
            color: #2F2D22;
            font-size: 2.4rem;
            margin-bottom: 15px;
            font-weight: bold;
        }
        h3 {
            color: var(--fonts-primary);
            font-size: .85rem;
            margin-bottom: 50px;
        }
    }
}